import React from 'react';
import { Link } from 'gatsby';
import FadeIn from '../../FadeIn';
import { ImagePass } from '../../Image/ImagePass';
import './ContactCtaBlock.scss';

export const ContactCtaBlock = (props) => {
  const {
    contactCtaTitleBold: titleBold,
    contactCtaTitleRegular: titleRegular,
    contactCtaContent: content,
    contactCtaLink: link,
    contactCtaImage: image
  } = props;
  return (
    <section className="contact-cta-block">
      <div className="background-container">
        <div className="wrap">
          <div className="contact-cta-block-content">
            <FadeIn className="content-wrapper">
              <h2><strong>{titleBold}</strong> {titleRegular}</h2>
              <div className="the-content" dangerouslySetInnerHTML={{__html: content}} />
              {link && <Link to={link.url} className="button-link">{link.title}</Link>}
            </FadeIn>
          </div>
          <div className="contact-cta-block-image">
            <ImagePass src={image} position="relative" />
          </div>
        </div>
      </div>
    </section>
  )
}
