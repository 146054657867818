import * as React from "react"

function Arrow(props) {
  return (
    <svg width={25} height={13} viewBox="0 0 25 13" {...props}>
      <title>Path</title>
      <path
        d="M24.714 5.95h-.001L19.61.916a.983.983 0 00-1.381.003.962.962 0 00.003 1.369l3.426 3.379H.977A.972.972 0 000 6.635c0 .535.437.968.977.968h20.681l-3.426 3.379a.962.962 0 00-.003 1.368c.38.38.999.38 1.381.004l5.103-5.033a.962.962 0 000-1.37z"
        fill="#FFF"
        fillRule="nonzero"
      />
    </svg>
  )
}

export default Arrow
